function handelParams(data){
    const arr = Object.keys(data)
    if (arr.length) {
        return arr.reduce((total,key) => {
            const str =  `${key}=${data[key]}`
            total = total === '' ? '?' + str : total + '&' + str
            return total
        },'')
    }
    return ''
}

export function request(url,data = {},method = 'GET'){
    let newUrl = url 
    const options = { method,body:JSON.stringify(data)}
    if (method === 'GET') {
        newUrl = url + handelParams(data)
        delete options.body
    }
    return new Promise((resove,reject) => {
        fetch(newUrl,options).then(res => {
            resove(res.json())
        }).catch(err => {
            reject(err)
        })
    })
}