import React, { memo,useEffect,useState} from "react";
import styles from "./index.module.css";

const Component = ({cityName}) => {
  const [date,setDate] = useState('');

  const zero = (num) => {
    if (num / 10 >= 1) return `${num}`
    return `0${num}`
  }

  const changeTime = () => {
    const time = new Date()
    const year = time.getFullYear()
    const month = time.getMonth() + 1
    const day = time.getDate()
    const hours = time.getHours()
    const minutes = time.getMinutes()
    const second = time.getSeconds()
    setDate(`${year}/${zero(month)}/${zero(day)} ${zero(hours)}：${zero(minutes)}：${zero(second)}`)
  }

  useEffect(() => { 
   const interval = setInterval(() => changeTime(), 1000)
    return () => {
      if (interval) clearInterval(interval)
    }
  },[])
  
  return (
    <div className={styles.title}>
      <p>{cityName}门店分布数据</p>
      {date !== '' && <p>时间：{date}</p>}
    </div>
  );
};

const Header = memo(Component);
Header.displayName = "Header";

export default Header;
