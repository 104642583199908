import React from "react";
import merge from "deepmerge";
import { baseChartOption, levelName } from "./const";

import getGeoJson from '../../utils/getGeoJson';
import { geoOption, provinceSeries, scatter, createInfoWindow } from "./dynamicConfig";
import { handelStoreRank, handelStoreLocation, handelStoreNum } from './handelData';
import { request } from '../../utils/request';
import { judgeCity } from '../../utils/other';

import Header from "../header";
import CircleProgress from "../circleProgress";
import Ranks from "../ranks";
import { message } from 'antd';
import "./index.css";

export default class App extends React.PureComponent {
  constructor() {
    super();
  }

  state = {
    parentInfo: [{ cityName: "全国", code: 100000, properties: { center: ['108.552500', '34.322700'] } }],
    storeRank: [], // 门店的排行数据
    curInfo: {
      total: 0,
      curName: '', // 默认显示门店最多的板块名，鼠标经过显示改变
      curValue: 0  // 默认显示门店最多的门店数量，鼠标经过显示改变
    }, // 当前市区门店总数量
  };

  geoJson = {};

  myChart = null;

  map = null;

  locationData = {
    store: [], // 门店定位信息
    users: [] // 用户定位信息
  }

  storeNum = []; //门店数量信息

  reqRecord = []; // 数据请求记录

  isCenterCity = false //是否地级市

  updateGeoOption = {
    center: ['108.552500', '34.322700'], // 初始为中国中心点
    nameToValue: {} // 映射的省市名对应的门店数量
  } //需要动态改变的geo配置

  get mapLevel() {
    return this.state.parentInfo.length; // 1-全国 2-区 3-区详情
  }

  render() {
    const { parentInfo, storeRank, curInfo } = this.state;
    const { cityName } = parentInfo[parentInfo.length - 1]
    const isShow = this.isShow()

    return (
      <>
        {this.mapLevel > 1 && (
          <div className="back" onClick={this.handleBackClick.bind(this)}>
            返回上一级
          </div>
        )}
        {isShow && <Header cityName={curInfo.curName} />}
        <div id="chart-panel" />
        {isShow && <CircleProgress curInfo={curInfo} cityName={cityName} />}
        {this.mapLevel === 1 && <Ranks list={storeRank} cityName={cityName} />}
        {isShow && <div className="bottomBg" />}
      </>
    );
  }
  // 控制顶部、底部、总数统计是否显示
  isShow() {
    if (this.mapLevel === 1) return true
    if (this.isCenterCity) {
      if (this.mapLevel < 4) return true
      return false
    }
    if (this.mapLevel < 5) return true
    return false
  }
  // 创建门店数量请求函数
  createNumReq(isCenterCity, { level, name }) {
    let params = { range: levelName[level], province: name }
    if (!name) delete params.province
    if (!isCenterCity && params.range === '市') {
      params = {
        ...params,
        province: this.state.parentInfo[1].cityName,
        city: name
      }
    }
    return [request('mapApi/map/storeAmountStatistics', params)]
  }
  // 创建门店定位请求函数
  createLocationReq(isCenterCity) {
    const { parentInfo } = this.state
    let params = {}
    if (isCenterCity) {
      params = {
        province: parentInfo[1].cityName,
        city: parentInfo[1].cityName,
        area: parentInfo[2].cityName
      }
    } else {
      params = {
        province: parentInfo[1].cityName,
        city: parentInfo[2].cityName,
        area: parentInfo[3].cityName
      }
    }
    return [
      request('mapApi/map/areaStoreStatistics', params),
      request('mapApi/map/userLocationRecord', params),
    ]
  }
  // 获取省市县配置
  getProvinceSeries(maskData) {
    const { mapData, curInfo, nameToValue } = handelStoreNum(this.geoJson.features, this.storeNum);
    this.updateGeoOption.nameToValue = nameToValue
    this.setState({ curInfo })
    let min = mapData[mapData.length - 1].value || 0;
    let max = mapData[0].value || 10000;

    if(this.mapLevel === 3 && min === max ) {
      min = 0;
    } else if (min === max) {
      min = 0
      max = 10000
    }
    return provinceSeries(mapData, maskData, { min, max })
  }
  // 获取geo配置
  getGeoOption() {
    if (this.mapLevel === 1) return geoOption(this.updateGeoOption)
    const max = this.isCenterCity ? 3 : 4
    const options = this.mapLevel !== max ?
      { zoom: 0.8, left: '40%', top: 'center' }
      :
      { zoom: 1.1, left: '35%', top: 'center' }
    return geoOption({ ...this.updateGeoOption, ...options })
  }
  // 设置echarts
  async setEcharts(adcode, reqestArr) {
    console.log(999)
    const len = reqestArr.length // 1: 门店数量 2.门店定位+用户定位
    const newReqestArr = [getGeoJson(adcode), ...reqestArr]
    try {
      const resArr = await Promise.all(newReqestArr);
      resArr.forEach((res, index) => {
        switch (index) {
          case 0:
            this.geoJson = res;
            break;
          case 1:
            if (len === 1) {
              this.storeNum = res.data
            } else {
              const { parentInfo } = this.state
              this.setState({ curTotal: res.data.length })
              this.locationData.stores = handelStoreLocation(res.data, parentInfo[parentInfo.length - 1])
            }
            break;
          case 2:
            this.locationData.users = res.data.map(item => [...item, '']);
            break;
          default:
            break;
        }
      })
    } catch (error) {
      message.error('网络异常～')
      return
    }
    // 处理左侧排行栏
    if (adcode === 100000) {
      this.setState({ storeRank: handelStoreRank(this.geoJson.features, this.storeNum) })
    }
    const maskData = len === 2 ? scatter(this.locationData) : []
    const provinceSeries = this.getProvinceSeries(maskData)
    const geoOption = this.getGeoOption()
    const option = merge.all([
      provinceSeries,
      baseChartOption,
      geoOption,
      { geo: { map: 'china1' } },
    ]);
    // console.log('option',option)
    window.echarts.registerMap("china1", this.geoJson);
    this.myChart.setOption(option, true);
  }
  // 创建和销毁 panel Dom节点
  handelPanel(bol) {
    const body = document.body
    if (bol) {
      const panel = document.createElement('div')
      panel.id = 'panel'
      body.appendChild(panel)
    } else {
      const panel = document.getElementById('panel')
      panel && body.removeChild(panel)
    }
  }
  // 去导航
  goNavigation = () => {
    if (!this.startlocation) {
      message.warning('正在尝试获取当前地理位置')
      this.getCurrentLocation()
      return
    }
    //路线规划
    this.handelPanel(true)
    this.driving = new AMap.Driving({
      policy: AMap.DrivingPolicy.LEAST_TIME,
      map: '',
      panel: 'panel',
    })
    this.driving.search(this.startlocation, this.endlocation, () => {
      document.getElementsByClassName('amap-call')[0].click()
    })
  }
  // 设置高德
  async setMap({ center, centroid }, storeId) {
    if (!storeId) return
    // document.querySelector('.back').style.color = '#000'
    try {
      const { data } = await request('mapApi/map/storeInfo', { storeId });
      if (!data) return
      const { lon, lat, storeName, storeHeadImg, displayAddress, phone } = data

      this.myChart.dispose();
      this.map = new AMap.Map('chart-panel', {
        zoom: 11,
        center: centroid || center,
        viewMode: '3D'
      });
      this.endlocation = [lon, lat]
      const marker = new AMap.Marker({
        position: new AMap.LngLat(lon, lat)
      });
      this.map.add(marker);

      //实例化信息窗体
      const content = [];
      content.push(`<img src=${storeHeadImg} style="width:60px;height:60px"/>
      <div style="display:inline-block;width:200px;">地址：${displayAddress}</div>`);
      content.push(`电话：${phone}`);
      const infoWindow = new AMap.InfoWindow({
        isCustom: true,  //使用自定义窗体
        content: createInfoWindow(storeName, content.join("<br/>"), this.goNavigation),
        offset: new AMap.Pixel(16, -45)
      });
      infoWindow.open(this.map, marker.getPosition());
    } catch (error) {
      message.error('网络异常～')
      return
    }
  }
  // 鼠标划过设置左上角城市名
  mouseover(data) {
    const { curInfo } = this.state
    const { name, value, seriesType } = data
    if (name === curInfo.curName || seriesType === "scatter") return
    // console.log(data)
    this.setState({
      curInfo: {
        ...curInfo,
        curName: name,
        curValue: value
      }
    })
  }
  // 点击下一级
  echartsMapClick(params) {
    const { data } = params
    const { cityCode, name, properties, id } = data;
    const { center, centroid } = properties

    const { parentInfo } = this.state
    // if (name === parentInfo[parentInfo.length - 1].cityName) return
    this.updateGeoOption.center = centroid || center   // 板块显示的中心点

    this.setState({
      parentInfo: [
        ...parentInfo,
        { cityName: name, code: cityCode, properties }
      ]
    },
      () => {
        const { cityName } = this.state.parentInfo[1]
        this.isCenterCity = judgeCity(cityName)
        let reqArr = null
        let max = 4
        if (this.isCenterCity) max = 3
        if (this.mapLevel < max) {
          console.log(1)
          reqArr = this.createNumReq(this.isCenterCity, properties)
          this.setEcharts(cityCode, reqArr);
        } else if (this.mapLevel === max) {
          console.log(2)
          reqArr = this.createLocationReq(this.isCenterCity)
          this.setEcharts(cityCode, reqArr);
        } else {
          console.log(3)
          this.setMap(properties, id);
        }
        this.reqRecord.push(reqArr)
      });
  }
  // 返回上一级
  handleBackClick() {
    const { parentInfo } = this.state
    parentInfo.pop();
    this.reqRecord.pop()
    this.setState(
      { parentInfo: [...parentInfo] },
      () => {
        const { code, properties } = parentInfo[parentInfo.length - 1]
        const { center, centroid } = properties
        const reqArr = this.reqRecord[this.reqRecord.length - 1]
        this.updateGeoOption.center = centroid || center   // 板块显示的中心点
        if (this.map) {
          // document.querySelector('.back').style.color = '#FFF'
          this.initEcharts(code, reqArr)
          this.driving && this.driving.clear()
          this.handelPanel(false)
        } else {
          this.setEcharts(code, reqArr);
        }

      }
    );
  }
  // 获取当前位置
  getCurrentLocation() {
    const geolocation = new AMap.Geolocation({ enableHighAccuracy: true })
    geolocation.getCurrentPosition((status, result) => {
      if (status === 'complete') {
        const { lng, lat } = result.position
        this.startlocation = [lng, lat]
      } else {
        message.error('获取当前地理位置失败')
      }
    })
  }
  // 初始化echarts
  initEcharts(code, reqArr, isFirst = true) {
    if (!isFirst) {
      this.map.clearMap()
      this.map = null
    }
    this.myChart = window.echarts.init(document.getElementById("chart-panel"));
    setTimeout(() => {
      //点击前解绑，防止点击事件触发多次
      this.myChart.off("click");
      this.myChart.off("mouseover");
      this.myChart.on("click", this.echartsMapClick.bind(this));
      this.myChart.on("mouseover", this.mouseover.bind(this));
      isFirst && this.setEcharts(code, reqArr);
    });
  }
  // 全国门店数据轮询刷新
  timedRefresh(isClose = false) {
    if (isClose) clearInterval(this.timer)
    this.timer = setInterval(() => {
      const { parentInfo } = this.state
      const { code } = parentInfo[parentInfo.length - 1]
      if (this.mapLevel === 1) {
        this.setEcharts(code, this.createNumReq(false, { level: 'country' }));
        return
      }
    }, 5000)
  }
  // 屏幕变化刷新页面
  screenResize() {
    let timer = null
    window.addEventListener("resize", () => {
      timer && clearTimeout(timer)
      timer = setTimeout(() => {
        window.location.reload()
      }, 300)
    })
  }

  componentDidMount() {
    const reqArr = this.createNumReq(false, { level: 'country' })
    this.reqRecord.push(reqArr)
    this.initEcharts(100000, reqArr)

    // this.timedRefresh()
    // this.screenResize()
    // 应用插件 
    AMap.plugin(['AMap.Geolocation', 'AMap.Driving'], () => {
      // 获取当前的经纬度
      this.getCurrentLocation()
    })
  }
  componentWillUnmount() {
    if (this.myChart) this.myChart.dispose();
    if (this.map) this.map.clearMap()
    if (this.timer) this.timedRefresh(true)
  }
}