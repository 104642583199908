import { memo } from "react";
import { Progress  } from "antd";
import styles from "./index.module.css";

const ColorData = [
  {trailColor:'rgba(68, 215, 182, 0.43)',strokeColor:'rgba(68, 215, 182, 1)'},
  {trailColor:'rgba(51, 222, 248, 0.43)',strokeColor:'rgba(51, 222, 248, 1)'},
  {trailColor:'rgba(37, 117, 243, 0.43)',strokeColor:'rgba(37, 117, 243, 1)'},
  {trailColor:'rgba(168, 1, 255, 0.43)',strokeColor:'rgba(168, 1, 255, 1)'},
  {trailColor:'rgba(54, 68, 255, 0.43)',strokeColor:'rgba(54, 68, 255, 1)'},
  {trailColor:'rgba(54, 68, 255, 0.43)',strokeColor:'rgba(54, 68, 255, 1)'},
  {trailColor:'rgba(54, 68, 255, 0.43)',strokeColor:'rgba(54, 68, 255, 1)'},
]

const Component = ({ list }) => {
  const rankData = list.filter((_,index) => index < 7);
   
  return (
    <div className={styles.rank}>
      <div className={styles.title}>全国门店排行</div>
      {
        rankData.map((item,index) => {
          const {name,value} = item
          const { trailColor,strokeColor} = ColorData[index]
          return <div key={index} className={styles.rankItem}>
            <span className={styles.name}>{name}</span>
            <Progress percent={value / 3} showInfo={false} trailColor={trailColor} strokeColor={strokeColor}  />
            <span className={styles.value}>{value}家</span>
          </div>
        })
      }
    </div>
  );
};

const Ranks = memo(Component);
Ranks.displayName = "Ranks";

export default Ranks;
