// 处理门店排行数据
export function handelStoreRank(cityList = [],storeNum = []){
  const arr = cityList.reduce((total,item) => {
    const { name } = item.properties
    const obj = storeNum.filter(item => item.place === name)[0]
    const num = obj ? obj.storeAmount : 0
    if (num === 0) return total
    total.push({ name,value: num,key:name })
    return total 
  },[])
  return arr.sort((a,b) => b.value - a.value)
}
 // 处理门店定位数据
export function handelStoreLocation(list,{ cityName, code, properties }) {
  if (!list.length) return []
  const options = { cityName,cityCode: code, properties }
  return list.map(item => {
    return {
      ...options,
      ...item,
      name:item.storeName,
      value: [item.lon, item.lat, item.storeName],
    }
  }) 
}
// 处理门店数量数据
export function handelStoreNum(list = [],storeNum = []){
  let curInfo = {total: 0, curName: '区域暂无门店',curValue: 0}
  const nameToValue = {}
  const mapData = list.reduce((arr,item) => {
    const { properties } = item
    const { name, adcode} = properties
    const obj = storeNum ? storeNum.filter(item => item.place === name)[0] : null
    const value = obj ? obj.storeAmount : 0
    curInfo.total += value
    if (value > curInfo.curValue) curInfo = {...curInfo,curName:name,curValue:value}
    arr.push({  
      name,
      cityCode: adcode,
      properties,
      value,
    });
    nameToValue[name] = value
    return arr
  },[]).sort((a, b) => {
    return b.value - a.value;
  });
  return { mapData, curInfo, nameToValue}
}
