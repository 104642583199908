import { areaScatterConfig, locationScatterConfig } from "./const";
import merge from "deepmerge";
export function geoOption(options = {}) {
  const { nameToValue, zoom, center, left, top } = options;
  return {
    geo: {
      zoom: zoom || 1.1,
      scaleLimit: {
        min: 0.5,
        max: 10,
      },
      left: left || "45%",
      top: top || "10%",
      center,
      roam: true,
      label: {
        show: true,
        color: "rgb(249, 249, 249)", //省份标签字体颜色
        formatter: (p) => {
          let { name } = p;
          const value = nameToValue[name] || 0;
          switch (name) {
            case "内蒙古自治区":
              name = "内蒙古";
              break;
            case "西藏自治区":
              name = "西藏";
              break;
            case "新疆维吾尔自治区":
              name = "新疆";
              break;
            case "宁夏回族自治区":
              name = "宁夏";
              break;
            case "广西壮族自治区":
              name = "广西";
              break;
            case "香港特别行政区":
              name = "香港";
              break;
            case "澳门特别行政区":
              name = "澳门";
              break;
            default:
              break;
          }
          if (!value) return name;
          return `${name}\n${value}`;
        },
        emphasis: {
          show: true,
          color: "#10b2e0",
        },
      },
      itemStyle: {
        areaColor: "rgba(0, 83, 181, 0.48)",
        borderColor: "rgba(25, 109, 255, 1)",
        borderWidth: 1.3,
        // shadowBlur: 15,
        // shadowColor: "rgb(58,115,192)",
        // shadowOffsetX: 7,
        // shadowOffsetY: 6,
        emphasis: {
          areaColor: "#1E62AC",
          borderWidth: 1.6,
          // shadowBlur: 10,
        },
      },
      regions: [
        {
          name: "海南省份",
          itemStyle: {
            normal: {
              opacity: 0,
            },
          },
          label: {
            show: false,
          },
        },
        {
          name: "海南省",
          label: {
            show: false,
          },
        },
      ],
    },
  };
}

export function provinceSeries(mapData = [], maskData = [], visualMinMax = {}) {
  const { max, min } = visualMinMax;
  const series = [
    {
      type: "map",
      geoIndex: 0,
      map: "china",
      roam: true,
      zoom: 1.3,
      data: mapData,
    },
    ...maskData,
  ];

  return {
    series,
    visualMap: {
      show: false,
      min: min || 0,
      max: max || 100,
      inRange: {
        color: [
          "rgba(0, 83, 181, 0.48)",
          "rgba(168, 1, 255, 1)",
          "rgba(37, 117, 243, 1)",
          "rgba(51, 222, 248, 1)",
          "rgba(68, 215, 182, 1)",
        ],
      },
      textStyle: {
        color: "red",
      },
    },
  };
}

// 门店标记和用户定位散点
export function scatter({ stores, users }) {
  const config = [
    merge.all([areaScatterConfig, { data: stores || [] }]),
    merge.all([
      // 定位散点
      locationScatterConfig,
      { data: users || [] },
    ]),
  ];
  return config;
}

//构建自定义信息窗体
export const createInfoWindow = (title, content, callBack) => {
  const info = document.createElement("div");
  info.className = "custom-info";

  // 定义顶部标题
  const top = document.createElement("div");
  const titleD = document.createElement("div");
  top.className = "info-top";
  titleD.innerHTML = title;

  top.appendChild(titleD);
  info.appendChild(top);

  // 定义中部内容
  const middle = document.createElement("div");
  middle.className = "info-middle";
  middle.style.backgroundColor = "white";
  middle.innerHTML = content;
  const btn = document.createElement("button");
  btn.innerText = "去导航";
  btn.className = "btn";
  btn.onclick = callBack;

  middle.appendChild(btn);
  info.appendChild(middle);

  // 定义底部内容
  const bottom = document.createElement("div");
  bottom.className = "info-bottom";
  bottom.style.position = "relative";
  bottom.style.top = "0px";
  bottom.style.margin = "0 auto";
  const sharp = document.createElement("img");
  sharp.src = "https://webapi.amap.com/images/sharp.png";

  bottom.appendChild(sharp);
  info.appendChild(bottom);
  return info;
};
