import geoJson from '../assets/JSON/geojson.json'

// 获取行政区数据
export default function getGeoJson(adcode, childAdcode = "") {
  if (adcode === 100000) {
    return Promise.resolve(geoJson)
  }
  
  return new Promise((resolve, reject) => {
    const insideFun = (adcode, childAdcode) => {
      window.AMapUI.loadUI(["geo/DistrictExplorer"], (DistrictExplorer) => {
        const districtExplorer = new DistrictExplorer();
        districtExplorer.loadAreaNode(adcode,(error, areaNode) => {
          if (error) {
            reject(error);
            return;
          }
          let Json = areaNode.getSubFeatures();
          if (Json.length === 0) {
            const parent = areaNode._data.geoData.parent.properties.acroutes;
            insideFun(parent[parent.length - 1], adcode);
            return;
          }
          if (childAdcode) {
            Json = Json.filter((item) => {
              return item.properties.adcode === childAdcode;
            });
          }
          const mapJson = {
            features: Json,
          };
          resolve(mapJson);
        });
      });
    }
    insideFun(adcode, childAdcode);
  });
}