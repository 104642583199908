import { memo } from "react";
import styles from "./index.module.css";

const Component = ({ curInfo,cityName }) => {
  const {total, curName, curValue}  = curInfo

  return (
    <div className={styles.circleProgress}>
      <div className={styles.total}>
        <p>{total}</p>
        <p className={styles.cityName}>{cityName}</p>
      </div>
      <div className={styles.most} >
        <p>{curValue}</p>
        <p className={styles.cityName}>{curName}</p>
      </div>
    </div>
  );
};

const Ranks = memo(Component);
Ranks.displayName = "TotalRanks";

export default Ranks;
