import deepmerge from 'deepmerge';
import allIcon from "../../assets/JSON/icon.json"

const baseScatterConfig = {
  type: "scatter",
  coordinateSystem: "geo",
  label: {
    show: true,
    formatter: "{@[2]}",
    padding:  [1, 6, 1, 6],
    borderRadius: 12,
    textStyle: {
      fontSize: 10,
      color: "#FFF",
    },
  },
}
const areaScatterConfig = deepmerge.all([baseScatterConfig,{
  symbol: allIcon.icon3,
  symbolSize: () => [20,26],
  zlevel: 7,
  label: {
    position: 'bottom' ,
    padding: 0,
    borderRadius: 0,
    textStyle: {
      fontSize: 10,
      color: "#FFF",
    },
  }
}])
const locationScatterConfig = deepmerge.all([baseScatterConfig,{
  symbol: 'circle',
  symbolSize: 1,
  zlevel: 7,
  itemStyle: {
    borderColor: 'yellow',
  },
  animation: false
}])

const baseChartOption = {
  backgroundColor: {
    type: 'linear',
    x: 0,
    y: 0,
    x2: 0,
    y2: 1,
    colorStops: [{
        offset: 0, color: '#153865' // 0% 处的颜色
    }, {
        offset: 1, color: '#0D123F' // 100% 处的颜色
    }],
    global: false // 缺省为 false
  },
  tooltip: {
    trigger: "axis",
    axisPointer: {
      type: "shadow",
    },
  },
  grid: {
    right: 0,
    top: 0,
    bottom: 0,
    width: 0,
  },
}

const levelName = {
  country: '全国',
  province:'省',
  city: '市',
  district: '区'
}

export {
  baseChartOption,
  areaScatterConfig,
  locationScatterConfig,
  levelName
}